import React from 'react'
import PropTypes from 'prop-types'
import AnimateHeight from 'react-animate-height'
import remark from 'remark'
import recommended from 'remark-preset-lint-recommended'
import remarkHtml from 'remark-html'
// import waterdrop from '../img/waterdrop-effenti.png'


export default class AvailableJobs extends React.Component{
    render() {
        const {availablejobs} = this.props;

        return (
            <div className="available-jobs-ctn">
                {availablejobs.map((availablejob, index) => {
                    return (
                        <div key={index} className="available-job">
                            <AvailableJob data={availablejob} />
                        </div>
                    )})}
            </div>
        )

    }
}

class AvailableJob extends React.Component {
    state = {height: 0};

    toggle = () => {
        const height = this.state.height;
        this.setState({
            height: height === 0 ? 'auto' : 0,
        })
    }

    render(){
        const { height } = this.state
        var mdToHtml = this.props.data.body;
        mdToHtml = remark().use(recommended).use(remarkHtml).processSync(mdToHtml).toString();

        return(
            <div className="content">
                <h3 className="title">{this.props.data.title}</h3>
                <p>{this.props.data.description}</p>
                <AnimateHeight
                    duration = { 500 }
                    height = { height }
                    animateOpacity={true}
                >
                    <div dangerouslySetInnerHTML={{ __html: mdToHtml }} />
                </AnimateHeight>
                <div className="career-btn-ctn">
                    <button className="btn white" onClick={this.toggle}>{height === 0 ? "Voir plus" : "Voir moins"}</button>
                </div>
            </div>
        )
    }
}

AvailableJobs.propTypes = {
    availablejobs: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            body: PropTypes.node.isRequired
        }),
    ),
    contentComponent: PropTypes.func
}
