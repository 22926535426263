
var animateFlag = true;
var element;

export default function(offset) {

    if(typeof window !== 'undefined') {

        window.addEventListener("scroll", function () {
            var element = document.getElementById('scrolled');

            if (element) {
                if (this.pageYOffset > offset) {
                    if (animateFlag) {
                        element.classList.add("animation");
                        animateFlag = false;
                    }
                } else {
                    element.classList.remove("animation");
                    animateFlag = true;
                }
            }
        })
    }
}
