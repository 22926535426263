import React from 'react'
import PropTypes from 'prop-types'
import {Link, graphql} from 'gatsby'

import Layout from '../components/Layout'
import AvailableJobs from "../components/AvailableJobs";
import Content, {HTMLContent} from '../components/Content';


import before from '../img/before.svg';
import after from '../img/after.svg';

import libere from '../img/libere.svg';
import benefice from '../img/benefice.svg';
import equipe from '../img/equipe.svg';
import collegue from '../img/collegue.svg';

import scroll from '../components/scroll';

export const EmploisTemplate = ({
    title, description, contentComponent, content, availableJobs, image
}) => {
    const PageContent = contentComponent || Content
    return (
        <Layout>
            {/* running the scroll animation */}
            {scroll(0)}

                <section className="main-ctn emplois">
                    <div className="page-head"
                         style={{backgroundImage: `url(${!!image.childImageSharp ? image.childImageSharp.fluid.src : image})`}}>
                        <div className="cover-ctn">
                            <div className="container">
                                <h1>{title}</h1>
                            </div>
                        </div>
                    </div>

                    <div className="wrapper-content effenti-bg spacers">
                        <div className="triangle-top"></div>
                        <div className="container">
                            <div id="scrolled" className="pagecontent-ctn">
                                {/*<PageContent content={content}/>*/}
                                <h1>POURQUOI EFFENTI ?</h1>
                                <div className="align left">
                                    <div className="main">
                                        Ici, Effenti appartient aux collègues
                                        <img src={collegue} alt="collegues icon"/>
                                    </div>
                                    <div className="secondary">
                                        <img src={before}  alt="lien" style={{width: '60px', height: '60px'}}/>
                                        <p>Chez nous, tu peux devenir actionnaire de l'entreprise et profiter de la force de l'équipe.</p>
                                    </div>
                                </div>
                                <div className="align right">
                                    <div className="main">
                                        Ici, on partage les bénéfices de l'entreprise
                                        <img src={benefice} alt="collegues icon" />
                                    </div>
                                    <div className="secondary">
                                        <p>C'est pas 1%, pas 5%, mais bien 15% des bénéfices annuels qui sont séparés à parts égales entre tous les collègues Effenti !</p>
                                        <img src={after}  alt="lien" style={{width: '60px', height: '60px'}}/>
                                    </div>
                                </div>
                                <div className="align left">
                                    <div className="main">
                                        Une équipe jeune
                                        <img src={equipe} alt="collegues icon" />
                                    </div>
                                    <div className="secondary">
                                        <img src={before}  alt="lien" style={{width: '60px', height: '60px'}}/>
                                        <p>Pas juste en âge... on est aussi jeune d’esprit! On est souple et agile dans notre humour. Bons vivants, bienvenue!</p>
                                    </div>
                                </div>
                                <div className="align right">
                                    <div className="main">
                                        Entreprise libérée = Pas de hiérarchie
                                        <img src={libere} alt="collegues icon" />
                                    </div>
                                    <div className="secondary">
                                        <p>Et oui, c’est possible. Notre modèle de gestion unique permet à chacun d’être partie prenante dans les décisions d’affaires de l’entreprise. Ici, tu as déjà ta place, même si tu débutes.</p>
                                        <img src={after}  alt="lien" style={{width: '60px', height: '60px'}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="availablejobs-red triangle-red-right">
                        <div className="container">
                            <h2>
                                Profils Recherchés
                            </h2>
                            <AvailableJobs availablejobs={availableJobs} />
                        </div>
                    </div>

                    <div className="send-cv-btn-ctn triangle-red-left">
                        <Link className="btn white" to="/contact">Envoie-nous ton cv</Link>
                    </div>
                </section>

        </Layout>
    )

}

EmploisTemplate.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    content: PropTypes.node.isRequired,
    contentComponent: PropTypes.func,
    availablejobs: PropTypes.array,
    image: PropTypes.object

}


const Emplois = ({data}) => {
    const { frontmatter } = data.markdownRemark
    const { markdownRemark : details } = data
    return (

        <EmploisTemplate
            title={frontmatter.title}
            description={frontmatter.description}
            content={details.html}
            contentComponent={HTMLContent}
            availableJobs={frontmatter.availablejobs}
            image={frontmatter.image}
        />

    )
}

Emplois.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object
        }),
    }),
}

export default Emplois

export const pageQuery = graphql`
  query EmploisTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "emplois" } }) {
      html
      frontmatter {
         title
         description
         availablejobs {
            title
            description
            body
         }
         image {
              childImageSharp {
                fluid(maxWidth: 1680, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
      }
    }
  }
`





